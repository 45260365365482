<template>
  <div>
    <b-card>
      <b-row align-v="center">
        <b-col
          md="3"
          sm="5"
        >
          <b-form-group label="User">
            <custom-select
              v-model="user"
              class="width-30"
              module-name="usersList"
              label="email"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="1"
          class="justify-content-start align-items-center"
        >
          <b-button
            :disabled="user === null"
            @click.prevent="onSearch"
          >
            Search
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <!-- Registrations table   -->
    <b-card v-if="result && result.users">
      <b-card-header>
        <b-card-title>Registrations</b-card-title>
      </b-card-header>
      <b-table
        :items="result.users"
        :fields="registrationTable"
        striped
        responsive
        small
      >
        <template #cell(user)="data">
          <b-row>
            <b-col>
              <b-link :to="{ name: 'user-detail', params: { id: data.item.id } }">
                {{ data.item.firstName }} {{ data.item.lastName }}<br>
                <small>{{ data.item.email }}</small>
              </b-link>
            </b-col>
            <b-col>
              <tags-list
                :tags="data.item.tags"
                :extras-tags="getUserExtraTags(data.item)"
                extras-variant="light-success"
              />
            </b-col>
          </b-row>
        </template>
        <template #cell(country)="data">
          {{ data.item.country.name }}
        </template>
      </b-table>
    </b-card>

    <!-- Orders table   -->
    <b-card v-if="result && result.orders">
      <b-card-header>
        <b-card-title>Orders</b-card-title>
      </b-card-header>
      <b-table
        :items="result.orders"
        :fields="ordersTable"
        striped
        responsive
        small
      >
        <template #cell(order_number)="data">
          <b-link :to="{name: 'order-detail', params: {id: data.value}}">
            {{ data.value }}
          </b-link>
        </template>

        <template #cell(order_ip)="data">
          {{ data.item.ip }}
        </template>

        <template #cell(user)="data">
          <b-row>
            <b-col>
              <b-link :to="{ name: 'user-detail', params: { id: data.item.user.id } }">
                {{ data.item.user.firstName }} {{ data.item.user.lastName }}<br>
                <small>{{ data.item.user.email }}</small>
              </b-link>
            </b-col>
            <b-col>
              <tags-list
                :tags="data.item.user.tags"
                :extras-tags="getUserExtraTags(data.item.user)"
                extras-variant="light-success"
              />
            </b-col>
          </b-row>
        </template>

        <template #cell(registration_ip)="data">
          {{ data.item.user.ip }}
        </template>

        <template #cell(country)="data">
          {{ data.item.user.country.name }}
        </template>
      </b-table>
    </b-card>

    <!-- Verifications table   -->
    <b-card v-if="result && result.verifications">
      <b-card-header>
        <b-card-title>Verifications</b-card-title>
      </b-card-header>
      <b-table
        :items="result.verifications"
        :fields="verificationTable"
        striped
        responsive
        small
      >
        <template #cell(user)="data">
          <b-link :to="{ name: 'user-detail', params: { id: data.item.user_id } }">
            {{ data.item.firstName }} {{ data.item.lastName }}<br>
            <small>{{ data.item.email }}</small>
          </b-link>
        </template>

        <template #cell(verification_ip)="data">
          {{ data.item.ip }}
        </template>

        <template #cell(registration_ip)="data">
          {{ data.item.registration_ip }}
        </template>

        <template #cell(country)="data">
          {{ data.item.country }}
        </template>
      </b-table>
    </b-card>

    <!-- Registrations table   -->
    <b-card v-if="result && result.consents">
      <b-card-header>
        <b-card-title>Consents</b-card-title>
      </b-card-header>
      <b-table
        :items="result.consents"
        :fields="consentTable"
        striped
        responsive
        small
      >
        <template #cell(user)="data">
          <b-row>
            <b-col>
              <b-link :to="{ name: 'user-detail', params: { id: data.item.id } }">
                {{ data.item.account.user.firstName }} {{ data.item.account.user.lastName }}<br>
                <small>{{ data.item.account.user.email }}</small>
              </b-link>
            </b-col>
            <b-col>
              <tags-list
                :tags="data.item.account.user.tags"
                :extras-tags="getUserExtraTags(data.item.account.user)"
                extras-variant="light-success"
              />
            </b-col>
          </b-row>
        </template>

        <template #cell(registration_ip)="data">
          {{ data.item.account.user.ip }}
        </template>

        <template #cell(country)="data">
          {{ data.item.account.user.country.name }}
        </template>
      </b-table>
    </b-card>

    <b-card v-if="result && result.ips">
      <b-card-header>
        <b-card-title>IPs list</b-card-title>
      </b-card-header>
      <b-list-group>
        <b-list-group-item
          v-for="(ip, key) in result.ips"
          :key="key"
        >
          {{ ip }}
        </b-list-group-item>
      </b-list-group>
    </b-card>

  </div>
</template>
<script>
import {
  BCard, BFormGroup, BButton, BRow, BCol, BCardHeader, BCardTitle, BTable, BLink, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import CustomSelect from '@/components/ui/forms/CustomSelect.vue'
import TagsList from '@/components/page/TagsList.vue'
import ToolsApi from '@/api/tools'
import { getUserExtraTags } from '@/plugins/userUtils'

const registrationTable = [
  {
    key: 'user',
    sortable: true,
  },
  {
    key: 'ip',
    sortable: true,
  },
  {
    key: 'country',
    sortable: true,
  },
]

const consentTable = [
  {
    key: 'user',
    sortable: true,
  },
  {
    key: 'ip',
    sortable: true,
  },
  {
    key: 'registration_ip',
    sortable: true,
  },
  {
    key: 'country',
    sortable: true,
  },
]

const ordersTable = [
  {
    key: 'order_number',
    sortable: true,
  },
  {
    key: 'state',
    sortable: false,
  },
  {
    key: 'order_ip',
    sortable: true,
  },
  {
    key: 'user',
    sortable: true,
  },
  {
    key: 'registration_ip',
    sortable: true,
  },
  {
    key: 'country',
    sortable: true,
  },
]

const verificationTable = [
  {
    key: 'user',
    sortable: true,
  },
  {
    key: 'verification_ip',
    sortable: true,
  },
  {
    key: 'registration_ip',
    sortable: true,
  },
  {
    key: 'country',
    sortable: true,
  },
]

export default {
  components: {
    CustomSelect,
    TagsList,
    BCard,
    BFormGroup,
    BButton,
    BRow,
    BCol,
    BCardTitle,
    BCardHeader,
    BTable,
    BLink,
    BListGroup,
    BListGroupItem,
  },
  data() {
    return {
      user: null,
      result: null,
      registrationTable,
      ordersTable,
      verificationTable,
      consentTable,
    }
  },
  computed: {
    userId() {
      return this.$route.params.id
    },
  },
  beforeMount() {
    if (this.userId) {
      this.user = this.userId
      this.onSearch()
    }
  },
  methods: {
    getUserExtraTags,
    onSearch() {
      this.showLoader(true)
      ToolsApi.getIpCheck(this.user).then(response => {
        this.result = response
      })
        .finally(() => {
          this.showLoader(false)
        })
    },
  },
}
</script>
